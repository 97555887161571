import classNames from 'classnames';
import { ChangeEvent, MouseEvent, useRef } from 'react';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { OscillatorTypeSelect } from '../metronome/Metronome';
import {
  tickSlice, selectPitch, selectTickState
} from './tickSlice';

import {
  Accidental,
  NATURAL_NOTES
} from '.';

export const TickControl = () => {
  const className = classNames(
    'd-flex',
    'flex-column',
    'align-items-center',
    'gap-3',
    'w-75',
  );
  return (
    <div className={className}>
      <VolumeSelect />
      <OscillatorTypeSelect />
      <NoteSelect />
      <AccidentalSelect />
      <OctaveSelect />
    </div>
  );
};

const NoteSelect = () => {
  const dispatch = useAppDispatch()
  const currentNote = useAppSelector(state => selectPitch(state).naturalNote);

  const buttons = NATURAL_NOTES.map(note => {
    const isSelected = (note === currentNote);
    const onClick = () => {
      dispatch(tickSlice.actions.setNaturalNote(note));
    };
    return (
      <Button
        key={note}
        active={isSelected}
        variant='outline-primary'
        style={
          isSelected ? {pointerEvents: 'none'} : undefined
        }
        onClick={
          isSelected ? undefined: onClick
        }
      >
        {note}
      </Button>
    );
  });

  return (
    <ButtonGroup className='w-100'>
      {buttons}
    </ButtonGroup>
  );
};

const AccidentalSelect = () => {
  const dispatch = useAppDispatch()
  const currentAccidental = useAppSelector(state => selectPitch(state).accidental);

  const accidentalLabels: [Accidental, string][] = [
    ['flat', '♭'],
    ['natural', '♮'],
    ['sharp', '♯'],
  ];
  const buttons = accidentalLabels.map(([accidental, label]) => {
    const isSelected = (accidental === currentAccidental);
    const onClick = () => {
      dispatch(tickSlice.actions.setAccidental(accidental));
    };
    return (
      <Button
        key={accidental}
        active={isSelected}
        variant='outline-secondary'
        style={
          isSelected ? {pointerEvents: 'none'} : undefined
        }
        onClick={
          isSelected ? undefined: onClick
        }
      >
        {label}
      </Button>
    );
  });

  return (
    <ButtonGroup className='w-75'>
      {buttons}
    </ButtonGroup>
  );
};

const OctaveSelect = () => {
  const dispatch = useAppDispatch()
  const currentOctave = useAppSelector(state => selectPitch(state).octave);

  const MIN_OCTAVE = 3;
  const MAX_OCTAVE = 6;

  const adjustOctave = (diff: number) => {
    const updatedOctave = currentOctave + diff;
    dispatch(tickSlice.actions.setOctave(updatedOctave));
    (document.activeElement as HTMLElement).blur();
  };

  const buttonVariant = 'dark';
  const className = classNames(
    'd-flex',
    'flex-row',
    'align-items-center',
    'justify-content-center',
    'font-monospace',
    'gap-3',
  );
  return (
    <div className={className}>
      <Button
        variant={buttonVariant}
        onClick={() => adjustOctave(-1)}
        disabled={currentOctave <= MIN_OCTAVE}
      >
        -
      </Button>
      <h3>
        <Badge bg='secondary'>{currentOctave}</Badge>
      </h3>
      <Button
        variant={buttonVariant}
        onClick={() => adjustOctave(1)}
        disabled={currentOctave >= MAX_OCTAVE}
      >
        +
      </Button>
    </div>
  );
};

export const VolumeSelect = () => {
  const dispatch = useAppDispatch();

  const selectedVolume = useAppSelector(
    state => selectTickState(state).volume
  );

  const rangeRef = useRef<HTMLInputElement>(null);

  const rangeOnMouseUp = (event: MouseEvent<HTMLInputElement>) => {
    const value = Number(event.currentTarget.value);
    dispatch(tickSlice.actions.setVolume(value));
  };

  const className = classNames(
    'd-flex',
    'flex-column',
    'align-items-center',
    'w-50',
  );
  return (
    <div className={className}>
      <Form.Range
        defaultValue={selectedVolume}
        min={0}
        max={100}
        step={10}
        ref={rangeRef}
        onMouseUp={rangeOnMouseUp}
      />
    </div>
  );
};
