import classNames from 'classnames';
import { KeyboardEvent as ReactKeyboardEvent, SyntheticEvent, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';

import { keysMatch, useAppDispatch, useAppSelector, useKeyPress } from '../../app/hooks';

import {
  metronomeSlice, selectMetronomeProps, selectMetronomeState
} from '../metronome/metronomeSlice';
import {
  Metronome,
  OscillatorType
} from './index';
export { Metronome };


const BPM_SHORTCUT_KEYS = [
  {key: 'ArrowDown'},
  {key: 'ArrowLeft'},
  {key: 'ArrowRight'},
  {key: 'ArrowUp'},
];
const START_STOP_SHORTCUT_KEY = {key: ' '};
export const SHORTCUT_KEYS = [START_STOP_SHORTCUT_KEY, ...BPM_SHORTCUT_KEYS];

export const MetronomeControl = () => {
  const className = classNames(
    'd-flex',
    'flex-column',
    'align-items-center',
    'gap-3',
    'w-75',
  );
  return (
    <div className={className}>
      <BpmControl />
      <BpmAdjustButtons />
      <StartStopButton />
    </div>
  );
};

const BpmControl = () => {
  const dispatch = useAppDispatch();

  const currentBpm = useAppSelector(
    (state) => selectMetronomeProps(state).bpm
  );

  const bpmRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    bpmRef.current!.value = currentBpm.toString();
  }, [currentBpm]);

  const setBpm = (bpm: number) => {
    dispatch(metronomeSlice.actions.setBpm(bpm));
  };

  // prevent keys in form from affecting playback
  const onKeyDown = (event: ReactKeyboardEvent) => {
    if (keysMatch(BPM_SHORTCUT_KEYS, event)) {
      event.stopPropagation();
    }
  };

  const onSubmit = (event: SyntheticEvent) => {
    // prevent page reload
    event.preventDefault();
    const bpm = Number(bpmRef.current!.value);
    setBpm(bpm);
    if (Metronome.isValidBpm(bpm)) {
      setBpm(bpm);
    } else {
      // revert invalid bpm to original value
      bpmRef.current!.value = currentBpm.toString();
    }
    (document.activeElement as HTMLElement).blur();
  };

  const className = classNames(
    'd-flex',
    'flex-column',
    'align-items-center',
    'w-100',
  );
  return (
    <Form className={className} onSubmit={onSubmit} onKeyDown={onKeyDown}>
      <i className='bi-speedometer2' style={{fontSize: '1.5rem'}} />
      <Form.Control
        type='number'
        className='text-center'
        required
        min={Metronome.MIN_BPM}
        max={Metronome.MAX_BPM}
        defaultValue={currentBpm}
        onBlur={() => submitButtonRef.current!.click()}
        ref={bpmRef}
      />
      <Button type='submit' ref={submitButtonRef} hidden />
    </Form>
  );
};

const BpmAdjustButtons = () => {
  const dispatch = useAppDispatch();

  const adjustBpm = (diff: number) => {
    dispatch(metronomeSlice.actions.adjustBpm(diff));
    (document.activeElement as HTMLElement).blur();
  };

  // keyboard shortcuts
  const handleKeyPress = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
        return adjustBpm(-5);
      case 'ArrowUp':
        return adjustBpm(+5);
      case 'ArrowLeft':
        return adjustBpm(-10);
      case 'ArrowRight':
        return adjustBpm(+10);
    }
  };
  useKeyPress(BPM_SHORTCUT_KEYS, handleKeyPress);

  const adjustButtons = [-10, -5, +5, +10].map(diff => {
    return (
      <Button key={diff} variant='secondary' style={{ width: '17%' }} onClick={() => adjustBpm(diff)}>
        {(diff > 0) ? `+${diff}` : diff.toString()}
      </Button>
    );
  });

  const className = classNames(
    'd-flex',
    'justify-content-around',
    'w-100',
  );
  return (
    <div className={className}>
      {adjustButtons}
    </div>
  );
};

const StartStopButton = () => {
  const dispatch = useAppDispatch();

  const currentIsStopped = useAppSelector(
    (state) => selectMetronomeState(state).isStopped
  );

  const toggleIsStopped = () => {
    dispatch(metronomeSlice.actions.setIsStopped(!currentIsStopped));
    (document.activeElement as HTMLElement).blur();
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    toggleIsStopped();
  };
  useKeyPress([START_STOP_SHORTCUT_KEY], handleKeyPress);

  const buttonVariant = currentIsStopped ? 'success' : 'danger';
  return (
    <Button
      className='w-100'
      variant={buttonVariant}
      onClick={toggleIsStopped}
    >
      {currentIsStopped ? 'start' : 'stop'}
    </Button>
  );
};

export const OscillatorTypeSelect = () => {
  const dispatch = useAppDispatch();

  const selectedOscillatorType = useAppSelector(
    state => selectMetronomeProps(state).oscillatorType
  );

  const oscillatorTypes = Object.values(OscillatorType);
  const width = 100 / oscillatorTypes.length;
  const buttons = oscillatorTypes.map(oscillatorType => {
    const isSelected = (oscillatorType === selectedOscillatorType);
    const onClick = () => {
      dispatch(metronomeSlice.actions.setOscillatorType(oscillatorType));
    };
    const style = {width: `${width}%`};
    return (
      <Button
        key={oscillatorType}
        active={isSelected}
        variant='outline-secondary'
        style={
          isSelected ? {...style, pointerEvents: 'none'} : style
        }
        onClick={
          isSelected ? undefined: onClick
        }
      >
        {oscillatorType}
      </Button>
    );
  });

  return (
    <ButtonGroup className='w-100'>
      {buttons}
    </ButtonGroup>
  );
};
