import {
  isAnyOf
} from '@reduxjs/toolkit';

import YouTubePlayer from 'youtube-player';
import type { YouTubePlayer as YouTubePlayerType, Options } from 'youtube-player/dist/types';

import type { AppStartListening } from '../../app/listenerMiddleware';

import { Metronome } from '../metronome/index';
import { selectionSlice } from '../selection/selectionSlice';


export abstract class PlayerRef {
  static ytPlayer: YouTubePlayerType | undefined;
  static isReady = false;

  static initialize(
    elementId: string,
    width: number,
    height: number,
    onReady: (player: YouTubePlayerType, event: CustomEvent) => void,
  ) {
    const playerVars: Options['playerVars'] = {
      autoplay: 0,
      color: 'white',
      controls: 1,
      disablekb: 0,
      enablejsapi: 0,
      fs: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      playsinline: 1,
      rel: 0,
    };
    const playerOptions = {
      width: width,
      height: height,
      playerVars: playerVars,
    };
    this.ytPlayer = YouTubePlayer(elementId, playerOptions, false);
    this.ytPlayer.on('ready', (event) => {
      this.isReady = true;
      onReady(this.getYtPlayer(), event);
    });
  }

  static uninitialize() {
    console.log('player ref uninit');
    this.getYtPlayer().destroy();
    this.isReady = false;
    this.ytPlayer = undefined;
  }

  static getYtPlayer() {
    return this.ytPlayer!;
  }
};

export abstract class Player {

  static PLAYBACK_START_BUFFER_SECONDS = 3;
  static metronome = new Metronome({
    bpm: 60,
  });
  static timeoutId = -1;
  static ytVideoId?: string;

  private static clearCountDown() {
    clearTimeout(this.timeoutId);
    this.timeoutId = -1;
    this.metronome.stop();
  }

  static reset() {
    this.clearCountDown();
    this.ytVideoId = undefined;
  }

  static isVideoLoaded() {
    return (this.ytVideoId !== undefined);
  }

  static setYtVideo(ytVideoId: string) {
    this.clearCountDown();
    const ytPlayer = PlayerRef.getYtPlayer();
    if (ytVideoId === undefined) {
      ytPlayer.stopVideo();
    } else{
      ytPlayer.cueVideoById(ytVideoId);
    }
    this.ytVideoId = ytVideoId;
  }

  static setSeconds(seconds: number) {
    if (!this.isVideoLoaded()) {
      return;
    }
    this.clearCountDown();
    const ytPlayer = PlayerRef.getYtPlayer();
    const seekSeconds = seconds - this.PLAYBACK_START_BUFFER_SECONDS;
    const allowSeekAhead = true;
    if (seekSeconds < 0) {
      ytPlayer.seekTo(0, allowSeekAhead);
      ytPlayer.pauseVideo();
      this.metronome.start(-seekSeconds);
      this.timeoutId = window.setTimeout(
        ytPlayer.playVideo,
        (-seekSeconds + Metronome.SCHEDULER_BUFFER) * 1000
      );
    } else {
      ytPlayer.seekTo(seekSeconds, allowSeekAhead);
      ytPlayer.playVideo();
    }
  }

  static togglePlay() {
    if (!this.isVideoLoaded()) {
      return;
    }
    this.clearCountDown();
    const ytPlayer = PlayerRef.getYtPlayer();
    ytPlayer.getPlayerState().then(
      (state) => {
        if (state === -1 || state === 2 || state === 5) {
          // unstarted, paused, cued
          ytPlayer.playVideo();
        } else if (state === 1) {
          // playing
          ytPlayer.pauseVideo();
        }
      }
    );
  }

  static toggleMute() {
    const ytPlayer = PlayerRef.getYtPlayer();
    ytPlayer.isMuted().then(
      (isMuted) => {
        if (isMuted) {
          ytPlayer.unMute();
        } else {
          ytPlayer.mute();
        }
      }
    );
  }

  static adjustTime(diff: number) {
    if (!this.isVideoLoaded()) {
      return;
    }
    this.clearCountDown();
    const ytPlayer = PlayerRef.getYtPlayer();
    ytPlayer.getCurrentTime().then(
      (seconds) => {
        ytPlayer.seekTo(seconds + diff, true);
      }
    );
  }

  static adjustVolume(diff: number) {
    const ytPlayer = PlayerRef.getYtPlayer();
    ytPlayer.getVolume().then(
      (volume) => {
        ytPlayer.setVolume(volume + diff);
      }
    );
  }
};

export const addListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(
      selectionSlice.actions.setVideo,
      selectionSlice.actions.setSection,
    ),
    effect: (action, listenerApi) => {
      if (!PlayerRef.isReady) {
        console.assert(false);
        return;
      }
      switch (action.type) {
        case selectionSlice.actions.setVideo.type: {
          const video = action.payload;
          Player.setYtVideo(video.ytVideoId);
          return;
        }
        case selectionSlice.actions.setSection.type: {
          const section = action.payload;
          Player.setSeconds(section.startSeconds);
          return;
        }
      };
    },
  });
};
