import { combineReducers, configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { listenerMiddleware } from './listenerMiddleware';
import sectionsReducer from '../features/section/sectionsSlice';
import videosReducer from '../features/video/videosSlice';
import presetsReducer from '../features/preset/presetsSlice';
import selectionReducer from '../features/selection/selectionSlice';
import pitchReducer from '../features/tick/tickSlice';
import metronomeReducer, {metronomePersistBlacklist} from '../features/metronome/metronomeSlice';

const rootReducer = combineReducers({
  videos: videosReducer,
  sections: sectionsReducer,
  presets: presetsReducer,
  selection: selectionReducer,
  pitch: pitchReducer,
  metronome: metronomeReducer,
});

const rootPersistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(listenerMiddleware.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
