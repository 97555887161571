import { PayloadAction } from '@reduxjs/toolkit';
import { SyntheticEvent, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { useAppDispatch } from '../../app/hooks';
import { RootState, store } from '../../app/store';

import { IconButton } from './Hoverable';

type ImportProps = {
  getActionsFromConfig: (config: any) => PayloadAction<any>[],
};

const ImportButton = ({
  getActionsFromConfig,
}: ImportProps) => {

  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = (event: SyntheticEvent) => {
    const input = inputRef.current!;
    input.click();
    (document.activeElement as HTMLElement).blur();
  };

  const onChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const element = event.currentTarget;
    const file = element.files![0];

    file.text().then(text => {
      const config = JSON.parse(text);
      getActionsFromConfig(config).map(dispatch);
    });
  };

  return (
    <IconButton
      className='bi-file-earmark-arrow-up'
      hoverClassName='bi-file-earmark-arrow-up-fill'
      variant='secondary'
      onClick={onClick}
    >
      <input type='file' ref={inputRef} onChange={onChange} hidden />
    </IconButton>
  );
};

type ExportProps = {
  defaultFilename: string,
  getConfigFromState: (state: RootState) => any,
};

const ExportButton = ({
  defaultFilename,
  getConfigFromState,
}: ExportProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const onClick = (event: SyntheticEvent) => {
    const state = store.getState();
    const config = getConfigFromState(state);

    const blob = new Blob(
      [JSON.stringify(config, null, 2)],
      { type: "text/plain" }
    );
    const url = URL.createObjectURL(blob);

    const anchorElement = anchorRef.current!;
    anchorElement.href = url;
    anchorElement.click();
    (document.activeElement as HTMLElement).blur();
  };

  return (
    <IconButton
      className='bi-file-earmark-arrow-down'
      hoverClassName='bi-file-earmark-arrow-down-fill'
      variant='secondary'
      onClick={onClick}
    >
      <a ref={anchorRef} download={defaultFilename} />
    </IconButton>
  );
};

export const ConfigButtonGroup = ({
  importProps,
  exportProps,
}: {
  importProps: ImportProps,
  exportProps: ExportProps,
}) => {
  return (
    <ButtonGroup className='d-flex gap-2'>
      <ExportButton {...exportProps} />
      <ImportButton {...importProps} />
    </ButtonGroup>
  );
};
