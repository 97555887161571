import { ChangeEvent, MouseEvent, useEffect, useRef } from 'react';

import type { EventType } from 'youtube-player/dist/eventNames';
import type { YouTubePlayer as YouTubePlayerType } from 'youtube-player/dist/types';

import Form from 'react-bootstrap/Form';

import { PlayerRef } from './playerSlice';

type ControlProps = {
  iconClassName: string,
  getValue: (player: YouTubePlayerType) => Promise<number>,
  setValue: (player: YouTubePlayerType, value: number) => Promise<void>,
  renderValue: (value: number) => string,
  eventType: EventType,
  extractValueFromEventData: (data: any) => number,

  // range slider
  minValue: number,
  maxValue: number,
  step: number,
};

const RangeControl = ({controlProps}: {controlProps: ControlProps}) => {
  const {
    iconClassName,
    getValue,
    setValue,
    renderValue,
    eventType,
    extractValueFromEventData,
    minValue,
    maxValue,
    step,
  } = controlProps;

  const rangeRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    const player = PlayerRef.getYtPlayer();
    const rangeElement = rangeRef.current!;
    const labelElement = labelRef.current!;

    player.on(eventType, (event) => {
      // force conversion to workaround incorrect youtube player event type
      const data = (event as unknown as {data: any}).data;
      const value = extractValueFromEventData(data);

      // update displays
      rangeElement.value = value.toString();
      labelElement.innerText = renderValue(value);
    });

    // hack to initialize displayed values
    // there is a bug where getValue returns undefined
    setValue(player, maxValue);
  }, []);

  const rangeOnMouseUp = (event: MouseEvent<HTMLInputElement>) => {
    const player = PlayerRef.getYtPlayer();
    const value = Number(event.currentTarget.value);
    setValue(player, value);
  };

  const rangeOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.currentTarget.value);
    labelRef.current!.innerText = renderValue(value);
  };

  const rangeProps = {
    min: minValue,
    max: maxValue,
    step: step,
    defaultValue: maxValue,
  };
  const range = <Form.Range
      ref={rangeRef}
      onChange={rangeOnChange}
      onMouseUp={rangeOnMouseUp}
      {...rangeProps}
  />

  return (
    <div className='d-flex flex-column align-items-center'>
      <i className={iconClassName} style={{fontSize: '1.5rem'}} />
      {range}
      <span ref={labelRef}>{renderValue(maxValue)}</span>
    </div>
  );
};

export const VolumeControl = () => {

  const controlProps: ControlProps = {
    iconClassName: 'bi-volume-up',
    getValue: player => player.getVolume(),
    setValue: (player, value) => player.setVolume(value),
    renderValue: value => value.toString(),
    eventType: 'volumeChange',
    extractValueFromEventData: data => data.volume,
    minValue: 5,
    maxValue: 100,
    step: 5
  };

  return (
    <RangeControl
      controlProps={controlProps}
    />
  );
};

export const RateControl = () => {

  const controlProps: ControlProps = {
    iconClassName: 'bi-speedometer',
    getValue: player => player.getPlaybackRate(),
    setValue: (player, value) => player.setPlaybackRate(value),
    renderValue: value => `${value.toFixed(2)}x`,
    eventType: 'playbackRateChange',
    extractValueFromEventData: data => data,
    minValue: 0.25,
    maxValue: 1.0,
    step: 0.05,
  };

  return (
    <RangeControl
      controlProps={controlProps}
    />
  );
};
