import { EntityId } from '@reduxjs/toolkit';
import { SyntheticEvent, useState } from 'react';

import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

import { AddForm, Field } from '../common/AddForm';
import { RemoveButton } from '../common/RemoveButton';
import { UpdateButton } from '../common/UpdateButton';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  SHORTCUT_KEYS
} from '../player/Player';
import {
  selectionSlice,
  selectSelectionState
} from '../selection/selectionSlice';
import {
  createVideo, selectArtists, selectVideoById,
  selectVideoIdsByArtist, Video,
  videosSlice
} from './videosSlice';


const VideoListItem = ({videoId}:{videoId: EntityId}) => {

  const video = useAppSelector(state => selectVideoById(state, videoId))!;

  const dispatch = useAppDispatch();
  const isPlayerLoaded = useAppSelector(
    state => selectSelectionState(state).isPlayerLoaded
  );
  const isSelected = useAppSelector(
    state => (selectSelectionState(state).videoId === videoId)
  );

  const onClick = () => {
    dispatch(selectionSlice.actions.setVideo(video));
  };

  const props = {
    action: !isSelected && isPlayerLoaded,
    active: isSelected,
    onClick: (isSelected || !isPlayerLoaded) ? undefined : onClick,
  };

  return (
    <ListGroup.Item {...props}>
      <div className='d-flex align-items-center'>
        <span>{video.title}</span>
        <div className='d-flex gap-2 ms-auto' onClick={e => e.stopPropagation()}>
          <UpdateVideoButton video={video} />
          <RemoveVideoButton video={video} />
        </div>
      </div>
    </ListGroup.Item>
  )
};

const AddVideoForm = () => {
  const fields: Field[] = [
    {
      name: 'artist',
      inputType: 'text',
      shouldFocus: true,
    },
    {
      name: 'title',
      inputType: 'text',
    },
    {
      name: 'ytVideoId',
      inputType: 'text',
      isValidValue: (value) => {
        return value.length === 11;
      },
      invalidValueMessage: '11 character Youtube video ID',
    },
  ];

  const getActionFromProps = (addProps: any) => {
    const video = createVideo(addProps);
    return videosSlice.actions.add(video);
  };

  return <AddForm
    componentProps={{
      fields: fields,
      fieldsDirection: 'vertical',
      getActionFromProps: getActionFromProps,
      disableKeys: SHORTCUT_KEYS,
    }}
  />;
};

const UpdateVideoButton = ({video}:{video: Video}) => {
  const fields = [
    {
      name: 'artist',
      inputType: 'text',
      placeholder: video.artist,
      shouldFocus: true,
    },
    {
      name: 'title',
      inputType: 'text',
      placeholder: video.title,
    },
    {
      name: 'ytVideoId',
      inputType: 'text',
      placeholder: video.ytVideoId,
    },
  ];

  const getActionFromProps = (updateProps: any) => {
    const {artist, title, ytVideoId} = updateProps;
    const changes = {
      artist: (artist === '') ? video.artist : artist,
      title: (title === '') ? video.title : title,
      ytVideoId: (ytVideoId === '') ? video.ytVideoId : ytVideoId,
    };
    return videosSlice.actions.updateObject([video, changes]);
  };

  return <UpdateButton
    componentProps={{
      fields: fields,
      fieldsDirection: 'vertical',
      getActionFromProps: getActionFromProps,
      disableKeys: SHORTCUT_KEYS,
    }}
  />;
};

const RemoveVideoButton = ({video}:{video: Video}) => {
  return <RemoveButton
    componentProps={{
      modalTitle: `Delete video "${video.artist} - ${video.title}"?`,
      modalBody: 'Its sections will also be deleted',
      removeAction: videosSlice.actions.remove(video.id),
    }}
  />;
};

const AddVideoModal = () => {
  const [show, setShow] = useState(false);

  const handleHide = (event: SyntheticEvent) => {
    setShow(false);
  };
  const handleShow = (event: SyntheticEvent) => {
    setShow(true);
  };
  const handleSubmit = (event: SyntheticEvent) => {
    handleHide(event);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button onClick={handleShow}>Add Video</Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        onSubmit={handleSubmit}
      >
        <Modal.Header>
          <Modal.Title>Add Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddVideoForm />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const VideoList = ({artist}: {artist: string}) => {
  const videoIds = useAppSelector(
    state => selectVideoIdsByArtist(state.videos, artist)
  );
  const videoListItems = videoIds.map(videoId => {
    return <VideoListItem key={videoId} videoId={videoId} />
  });

  return (
    <ListGroup>
      {videoListItems}
    </ListGroup>
  );
};

export const VideoListWrapper = () => {
  const artist = useAppSelector(
    state => selectSelectionState(state).artist
  );
  if (artist === undefined) {
    return null;
  } else {
    return <VideoList artist={artist} />
  }
};

const ArtistListItem = ({artist}: {artist: string}) => {
  const dispatch = useAppDispatch();

  const isSelected = useAppSelector(
      state => (selectSelectionState(state).artist === artist)
  );

  const onClick = () => {
    dispatch(selectionSlice.actions.setArtist(artist));
  };

  const props = {
    action: !isSelected,
    active: isSelected,
    onClick: isSelected ? undefined : onClick,
  };

  return (
    <ListGroup.Item {...props}>
      <div className='d-flex align-items-center'>
        <span>{artist}</span>
      </div>
    </ListGroup.Item>
  )
};

export const ArtistList = () => {
  const artists = useAppSelector(selectArtists);
  const artistListItems = artists.map(artist =>
    <ArtistListItem key={artist} artist={artist} />
  );
  return (
    <>
    <ListGroup>
      {artistListItems}
    </ListGroup>
    <br />
    <AddVideoModal />
    </>
  );
};
