import {
  useState,
  ComponentPropsWithoutRef,
  forwardRef,
  Ref,
} from 'react';

import Button from 'react-bootstrap/Button';

type Props = {
  className: string,
  hoverClassName: string,
  isSelected?: boolean,
};

export const Icon = forwardRef((
  {className, hoverClassName, isSelected = false, ...props}: Props & ComponentPropsWithoutRef<'i'>,
  ref: Ref<HTMLElement>,
) => {
  const [isHovered, setIsHovered] = useState(false);
  const toggleIsHovered = () => {
    setIsHovered(!isHovered);
  };
  return (
    <i
      className={(isHovered || isSelected) ? hoverClassName : className}
      onMouseEnter={toggleIsHovered}
      onMouseLeave={toggleIsHovered}
      ref={ref}
      {...props}
    />
  );
});


export const IconButton = forwardRef((
  {className, hoverClassName, isSelected = false, ...props}: Props & ComponentPropsWithoutRef<typeof Button>,
  ref: Ref<HTMLButtonElement>,
) => {
  const [isHovered, setIsHovered] = useState(false);
  const toggleIsHovered = () => {
    setIsHovered(!isHovered);
  };
  return (
    <Button
      onMouseEnter={toggleIsHovered}
      onMouseLeave={toggleIsHovered}
      ref={ref}
      {...props}
    >
      <i
        className={(isHovered || isSelected) ? hoverClassName : className}
      />
      {props.children}
    </Button>
  );
});
