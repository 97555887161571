import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  KeyboardEvent as ReactKeyboardEvent,
} from 'react';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type ShortcutKey = {
  key: string,
  ctrl?: boolean,
  meta?: boolean,
  shift?: boolean,
};

export const keyMatch = (shortcutKey: ShortcutKey, event: KeyboardEvent | ReactKeyboardEvent) => {
  if ((shortcutKey.ctrl ?? false) !== event.ctrlKey) {
    return false;
  }
  if ((shortcutKey.meta ?? false) !== event.metaKey) {
    return false;
  }
  if ((shortcutKey.shift ?? false) !== event.shiftKey) {
    return false;
  }
  return shortcutKey.key === event.key;
};

export const keysMatch = (shortcutKeys: ShortcutKey[], event: KeyboardEvent | ReactKeyboardEvent) => {
  return shortcutKeys.some((shortcutKey) => keyMatch(shortcutKey, event));
};

// https://devtrium.com/posts/how-keyboard-shortcut#extracting-the-logic-into-a-reusable-custom-hook
export const useKeyPress = (shortcutKeys: ShortcutKey[], callback: (event: KeyboardEvent) => void, node = null) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      if (keysMatch(shortcutKeys, event)) {
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [shortcutKeys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      targetNode.removeEventListener('keydown', handleKeyPress);
    }
  }, [handleKeyPress, node]);
};
