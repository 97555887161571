import { EntityId } from '@reduxjs/toolkit';

import ListGroup from 'react-bootstrap/ListGroup';

import { AddForm, Field } from '../common/AddForm';
import { RemoveButton } from '../common/RemoveButton';
import { UpdateButton } from '../common/UpdateButton';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  SHORTCUT_KEYS
} from '../player/Player';
import {
  selectionSlice,
  selectSelectionState
} from '../selection/selectionSlice';
import {
  selectVideoById
} from '../video/videosSlice';
import {
  createSection, Section, sectionsSelectors, sectionsSlice, selectSectionIdsByYtVideoId
} from './sectionsSlice';


const SENTINEL_SECTION_ID: EntityId = -1;
const SENTINEL_SECTION: Section = {
  id: SENTINEL_SECTION_ID,
  ytVideoId: '',
  name: 'START',
  startSeconds: 0,
};

const getMinutesSeconds = (seconds: number) => {
  return {
    minutes: Math.floor(seconds / 60),
    seconds: seconds % 60,
  };
};

const SectionListItem = ({sectionId}: {sectionId: EntityId}) => {
  const dispatch = useAppDispatch()
  const section = useAppSelector(
    state => sectionsSelectors.selectById(state, sectionId)!
  ) || SENTINEL_SECTION;
  const isSelected = useAppSelector(
    state => (selectSelectionState(state).sectionId === sectionId)
  );

  const onClick = () => {
    dispatch(selectionSlice.actions.setSection(section));
  };
  const props = {
    // allow re-selecting section
    action: true,
    onClick: onClick,
    active: isSelected,
  };

  const getTimestampString = (timestamp: number): string => {
    const {minutes, seconds} = getMinutesSeconds(timestamp);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const updateSectionButton = (sectionId === SENTINEL_SECTION_ID)
    ? null
    : <UpdateSectionButton section={section} />;

  const removeSectionButton = (sectionId === SENTINEL_SECTION_ID)
    ? null
    : <RemoveSectionButton section={section} />;

  return (
    <ListGroup.Item {...props}>
      <div className='d-flex align-items-center'>
        <span style={{ width: '30%'}}>{section.name}</span>
        <div style={{margin: '0 5%'}} className='vr' />
        <span style={{ width: '40%'}}>{getTimestampString(section.startSeconds)}</span>
        <div className='d-flex gap-2 ms-auto' onClick={e => e.stopPropagation()}>
          {updateSectionButton}
          {removeSectionButton}
        </div>
      </div>
    </ListGroup.Item>
  );
};

const UpdateSectionButton = ({section}:{section: Section}) => {
  const {minutes, seconds} = getMinutesSeconds(section.startSeconds);
  const fields = [
    {
      name: 'name',
      inputType: 'text',
      placeholder: section.name,
      shouldFocus: true,
      formProps: { width: '50%'},
    },
    {
      name: 'mm',
      inputType: 'number',
      placeholder: minutes.toString().padStart(2, '0'),
      formProps: { width: '20%'},
    },
    {
      name: 'ss',
      inputType: 'number',
      placeholder: seconds.toString().padStart(2, '0'),
      formProps: { width: '20%'},
    },
  ];

  const getActionFromProps = (updateProps: any) => {
    const {name, mm, ss} = updateProps;
    const updatedMinutes = (mm === '') ? minutes : Number(mm);
    const updatedSeconds = (ss === '') ? seconds : Number(ss);
    const startSeconds = 60 * updatedMinutes + updatedSeconds;
    const update = {
      id: section.id,
      changes: {
        name: (name == '') ? section.name : name,
        startSeconds: startSeconds,
      },
    };
    return sectionsSlice.actions.update(update);
  };

  return <UpdateButton
    componentProps={{
      fields: fields,
      getActionFromProps: getActionFromProps,
      disableKeys: SHORTCUT_KEYS,
    }}
  />;
};

const RemoveSectionButton = ({section}:{section: Section}) => {
  return <RemoveButton
    componentProps={{
      modalTitle: `Delete section "${section.name}"?`,
      removeAction: sectionsSlice.actions.remove(section.id),
    }}
  />;
};

const AddSectionForm = ({ytVideoId}: {ytVideoId: string}) => {
  const fields: Field[] = [
    {
      name: 'name',
      inputType: 'text',
    },
    {
      name: 'mm',
      inputType: 'number',
      labelStyle: {width: '30%'},
      isValidValue: (value) => {
        return Number.isInteger(value) && value >= 0;
      },
      invalidValueMessage: 'positive integer',
    },
    {
      name: 'ss',
      inputType: 'number',
      labelStyle: {width: '30%'},
      isValidValue: (value) => {
        return Number.isInteger(value) && value >= 0 && value < 60;
      },
      invalidValueMessage: 'positive integer less than 60',
    },
  ];

  const getActionFromProps = (addProps: any) => {
    const {name, mm, ss} = addProps;
    const startSeconds = Number(mm) * 60 + Number(ss);
    const section = createSection({
      name: name,
      startSeconds: startSeconds,
      ytVideoId: ytVideoId,
    });
    return sectionsSlice.actions.add(section);
  };

  return <AddForm
    componentProps={{
      fields: fields,
      fieldsDirection: 'horizontal',
      getActionFromProps: getActionFromProps,
      disableKeys: SHORTCUT_KEYS,
    }}
  />;
};

export const SectionListWrapper = () => {
  const dispatch = useAppDispatch();

  const videoId = useAppSelector(
    state => selectSelectionState(state).videoId
  );
  if (videoId === undefined) {
    return null;
  } else {
    return <SectionList videoId={videoId} />
  }
};

const SectionList = ({videoId}: {videoId: EntityId}) => {
  const ytVideoId = useAppSelector(
    state => selectVideoById(state, videoId)?.ytVideoId
  );
  const sectionIds = useAppSelector(
    state => selectSectionIdsByYtVideoId(state.sections, ytVideoId)
  );

  // this can happen when setting entire state via importing config
  if (ytVideoId === undefined) {
    return null;
  };

  const addSectionForm = <AddSectionForm ytVideoId={ytVideoId} />;
  const sectionListItems = [SENTINEL_SECTION_ID, ...sectionIds].map(sectionId => {
    const props = {
      key: sectionId,
      sectionId: sectionId,
    };
    return <SectionListItem {...props}/>
  });

  return (
    <div className='d-flex flex-column gap-2'>
      <ListGroup>
        {sectionListItems}
      </ListGroup>
      <br/>
      {addSectionForm}
    </div>
  );
};
