import { SectionProps } from './sectionsSlice';

export const initialSectionProps: SectionProps[] = [
  // Master of Puppets
  {
    ytVideoId: 'h0jTR-EdcDI',
    name: 'solo 1 (easy)',
    startSeconds: 3 * 60 + 30,
  },
  {
    ytVideoId: 'h0jTR-EdcDI',
    name: 'solo 2 (hard)',
    startSeconds: 5 * 60 + 43,
  },

  // Not Alone
  {
    ytVideoId: 'mS0ZJsDC76w',
    name: 'chorus 1',
    startSeconds: 38
  },
  {
    ytVideoId: 'mS0ZJsDC76w',
    name: 'solo',
    startSeconds: 1 * 60 + 50,
  },

  // Six
  {
    ytVideoId: 'CA_aMp-MvLE',
    name: 'chorus',
    startSeconds: 48,
  },
  {
    ytVideoId: 'CA_aMp-MvLE',
    name: 'interlude',
    startSeconds: 1 * 60 + 37,
  },
  {
    ytVideoId: 'CA_aMp-MvLE',
    name: 'solo',
    startSeconds: 2 * 60 + 8,
  },

  // What A Horrible Night To Have A Curse
  {
    ytVideoId: 'mEACzoMJuMA',
    name: 'chorus',
    startSeconds: 1 * 60 + 21,
  },
  {
    ytVideoId: 'mEACzoMJuMA',
    name: 'solo',
    startSeconds: 2 * 60 + 1,
  },
];
