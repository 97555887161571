import { EntityId } from '@reduxjs/toolkit';
import { ComponentProps, SyntheticEvent, useEffect, useState } from 'react';

import {
  closestCenter, DndContext, PointerSensor, useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext, useSortable, verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AddForm, Field } from '../common/AddForm';
import { Icon } from '../common/Hoverable';
import { RemoveButton } from '../common/RemoveButton';
import { UpdateButton } from '../common/UpdateButton';

import {
  createPreset, Preset, presetsSelectors, presetsSlice,
  selectPresetsState, updateBpmFromMetronome
} from './presetsSlice';

import ListGroup from 'react-bootstrap/ListGroup';

import {
  SHORTCUT_KEYS,
  Metronome,
} from '../metronome/Metronome';


export const PresetList = () => {
  const dispatch = useAppDispatch();

  // initially populate ids from redux, then use
  // component state for preserving ordering
  // select again if any items are removed or added
  const presetIds = useAppSelector(presetsSelectors.selectIds, (a, b) => (a.length === b.length));
  const [ids, setIds] = useState<typeof presetIds>(presetIds);

  useEffect(() => {
    setIds(presetIds);
  }, [presetIds]);

  const handleDragEnd: ComponentProps<typeof DndContext>['onDragEnd'] = (event) => {
    const {active, over} = event;
    if (!over || active.id === over.id) {
      return;
    }

    setIds((ids) => {
      const fromIndex = ids.indexOf(active.id);
      const toIndex = ids.indexOf(over.id);

      const updatedIds = arrayMove(ids, fromIndex, toIndex);
      dispatch(presetsSlice.actions.setIds(updatedIds));
      return updatedIds;
    });
  };

  // introduce distance constraint so that drag handler
  // doesn't conflict with click handler
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const presetListItems = ids.map(presetId => {
    const props = {
      key: presetId,
      presetId: presetId,
    };
    return <PresetListItem {...props}/>
  });

  return (
    <div className='d-flex flex-column gap-2'>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <ListGroup>
          <SortableContext
            items={ids}
            strategy={verticalListSortingStrategy}
          >
            {presetListItems}
          </SortableContext>
        </ListGroup>
      </DndContext>
      <br />
      <AddPresetForm />
    </div>
  );
};


const PresetListItem = ({presetId}: {presetId: EntityId}) => {
  const dispatch = useAppDispatch()

  const preset = useAppSelector(
    state => presetsSelectors.selectById(state, presetId)
  );
  const isSelected = useAppSelector(
    state => (selectPresetsState(state).selectedId === presetId)
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: presetId});
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  // deleted preset
  if (preset === undefined) {
    return null;
  }

  const onClick = () => {
    dispatch(presetsSlice.actions.selectPreset(preset));
  };

  const onSave = (event: SyntheticEvent) => {
    dispatch(updateBpmFromMetronome(presetId));
  };

  const props = {
    action: !isSelected,
    onClick: isSelected ? undefined : onClick,
    active: isSelected,
    ref: setNodeRef,
    style: style,
    ...attributes,
    ...listeners,
  };

  return (
    <ListGroup.Item {...props}>
      <div className='d-flex align-items-center'>
        <span style={{ width: '40%'}}>{preset.name}</span>
        <div style={{margin: '0 5%'}} className='vr' />
        <span style={{ width: '20%'}}>{preset.bpm}</span>
        <div className='d-flex gap-2 ms-auto' onClick={e => e.stopPropagation()}>
          <Icon
            className='bi-save'
            hoverClassName='bi-save-fill'
            isSelected={false}
            onClick={onSave}
          />
          <UpdatePresetButton preset={preset} />
          <RemovePresetButton preset={preset} />
        </div>
      </div>
    </ListGroup.Item>
  );
};

const UpdatePresetButton = ({preset}:{preset: Preset}) => {

  const fields = [
    {
      name: 'name',
      inputType: 'text',
      placeholder: preset.name,
    },
    {
      name: 'bpm',
      inputType: 'number',
      placeholder: preset.bpm.toString(),
      formProps: { width: '40%'},
      shouldFocus: true,
    },
  ];

  const getActionFromProps = (updateProps: any) => {
    const {name, bpm} = updateProps;
    const update = {
      id: preset.id,
      changes: {
        name: (name == '') ? preset.name : name,
        bpm: (bpm == '') ? preset.bpm : Number(bpm),
      },
    };
    return presetsSlice.actions.update(update);
  };

  return <UpdateButton
    componentProps={{
      fields: fields,
      getActionFromProps: getActionFromProps,
      disableKeys: SHORTCUT_KEYS,
    }}
  />;
};

const RemovePresetButton = ({preset}:{preset: Preset}) => {
  return <RemoveButton
    componentProps={{
      modalTitle: `Delete preset "${preset.name}"?`,
      removeAction: presetsSlice.actions.remove(preset.id),
    }}
  />;
};

const AddPresetForm = () => {
  const fields: Field[] = [
    {
      name: 'name',
      inputType: 'text',
    },
    {
      name: 'bpm',
      inputType: 'number',
      labelStyle: {width: '40%'},
      isValidValue: (value) => {
        return Number.isInteger(value) && Metronome.isValidBpm(value);
      },
      invalidValueMessage: Metronome.validBpmMessage(),
    },
  ];

  const getActionFromProps = (addProps: any) => {
    const {name, bpm} = addProps;
    const preset = createPreset({
      name: name,
      bpm: Number(bpm),
    });
    return presetsSlice.actions.add(preset);
  };

  return <AddForm
    componentProps={{
      fields: fields,
      fieldsDirection: 'horizontal',
      getActionFromProps: getActionFromProps,
      disableKeys: SHORTCUT_KEYS,
    }}
  />;
};
