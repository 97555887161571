import { ConfigButtonGroup } from '../common/ConfigButtonGroup';

import { presetsSlice, createPreset } from '../preset/presetsSlice';

import { RootState } from '../../app/store';


export const MetronomeConfigButtonGroup = () => {

  const getActionsFromConfig = (config: any) => {
    const presets = config.presets.map(createPreset);
    return [
      presetsSlice.actions.setAll(presets),
    ];
  };

  const getConfigFromState = (state: RootState) => {
    const presetsProps = Object.values(state.presets.entities).map(
      obj => {
        const {id, ...props} = obj!;
        return props;
      }
    );
    const config = {
      presets: presetsProps,
    };
    return config;
  };

  return (
    <ConfigButtonGroup
      importProps={{
        getActionsFromConfig: getActionsFromConfig,
      }}
      exportProps={{
        defaultFilename: 'metronomeConfig.json',
        getConfigFromState: getConfigFromState,
      }}
    />
  );
};
