// global singleton
const audioContext = new AudioContext();

export enum OscillatorType {
  SINE = 'sine',
  SQUARE = 'square',
  SAWTOOTH = 'sawtooth',
  TRIANGLE = 'triangle'
}

const scheduleNote = (
  time: number,
  oscillatorType: OscillatorType,
  frequency: number,
  gain: number,
) => {
  const oscillatorNode = new OscillatorNode(
    audioContext,
    {
      type: oscillatorType,
      frequency: frequency,
    }
  );
  const gainNode = new GainNode(audioContext, {gain: gain});

  oscillatorNode.connect(gainNode).connect(audioContext.destination);
  oscillatorNode.start(time);
  oscillatorNode.stop(time + 0.05);
};

export type MetronomeProps = {
  bpm: number,
  oscillatorType: OscillatorType,
  frequency: number,
  gain: number,
};

export const defaultMetronomeProps: MetronomeProps = {
  bpm: 100,
  oscillatorType: OscillatorType.SINE,
  frequency: 440,
  gain: 0.7,
};

export class Metronome {
  static SCHEDULER_BUFFER: number = 0.1;
  static MIN_BPM = 5;
  static MAX_BPM = 400;
  static MIN_GAIN = 0;
  static MAX_GAIN = 1;

  static isValidBpm = (bpm: number) => {
    return bpm >= Metronome.MIN_BPM && bpm <= Metronome.MAX_BPM;
  };

  static validBpmMessage = () => {
    return `bpm must be between ${Metronome.MIN_BPM} and ${Metronome.MAX_BPM}`
  };

  static isValidGain = (gain: number) => {
    return gain >= Metronome.MIN_GAIN && gain <= Metronome.MAX_GAIN;
  };

  static validGainMessage = () => {
    return `gain must be between ${Metronome.MIN_GAIN} and ${Metronome.MAX_GAIN}`
  };

  static isValidProps = (props: MetronomeProps) => {
    let isValid = true;
    if (!this.isValidBpm(props.bpm)) {
      console.log(this.validBpmMessage());
      isValid = false;
    }
    if (!this.isValidGain(props.gain)) {
      console.log(this.validGainMessage());
      isValid = false;
    }
    return isValid;
  };

  props: MetronomeProps;
  timeoutId?: number;

  constructor(metronomeProps: Partial<MetronomeProps> = {}) {
    this.props = {...defaultMetronomeProps, ...metronomeProps};
  }

  private runOnce = (
      beatNum: number,
      noteTime: number,
      shouldStop: (
        noteTime: number,
      ) => boolean,
  ) => {
    const endTime = audioContext.currentTime + Metronome.SCHEDULER_BUFFER;
    while (noteTime < endTime) {
      scheduleNote(noteTime, this.props.oscillatorType, this.props.frequency, this.props.gain);
      beatNum += 1;
      noteTime += 60.0 / this.props.bpm;
      if (shouldStop(noteTime)) {
        delete this.timeoutId;
        return;
      }
    }
    this.timeoutId = window.setTimeout(
      () => this.runOnce(beatNum, noteTime, shouldStop),
      25
    );
  };

  setProps = (props: MetronomeProps) => {
    if (!Metronome.isValidProps(props)) {
      return;
    }
    this.props = props;
  };

  start = (durationSeconds?: number) => {
    if (!this.isStopped()) {
      return;
    }
    // workaround for chrome autoplay restrictions
    audioContext.resume().then(() => {
      this.startInner(durationSeconds);
    });
  }

  private startInner = (durationSeconds?: number) => {
    const initBeatNum = 0;
    const initNoteTime = audioContext.currentTime + Metronome.SCHEDULER_BUFFER;

    const shouldStop = (noteTime: number) => {
      if (durationSeconds !== undefined && noteTime >= initNoteTime + durationSeconds) {
        return true;
      }
      return false;
    };

    this.runOnce(initBeatNum, initNoteTime, shouldStop);
  };

  stop = () => {
    clearTimeout(this.timeoutId);
    delete this.timeoutId;
  };

  isStopped = () => {
    return (this.timeoutId === undefined);
  };
}
