import { PresetProps } from './presetsSlice';

export const initialPresetProps: PresetProps[] = [
  {
    name: 'edit me',
    bpm: 80,
  },
  {
    name: 'drag me',
    bpm: 120,
  },
  {
    name: 'delete me',
    bpm: 160,
  },
];
