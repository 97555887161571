import { createEntityAdapter, createSelector, createSlice, EntityId, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from '../../app/store';
import { initialVideoProps } from './seed';

export type VideoProps = {
  artist: string,
  title: string,
  ytVideoId: string,
};

export type Video = {id: EntityId} & VideoProps;

export const createVideo = (videoProps: VideoProps): Video => {
  return {
    id: uuidv4(),
    ...videoProps,
  }
};

const compareVideos = (a: Video, b: Video): number => {
  const artistCompare = a.artist.localeCompare(b.artist);
  if (artistCompare !== 0) {
    return artistCompare;
  }
  return a.title.localeCompare(b.title);
}

const videosAdapter = createEntityAdapter<Video>({
  sortComparer: compareVideos,
});

const initialState = videosAdapter.addMany(
  videosAdapter.getInitialState(),
  initialVideoProps.map(createVideo)
);

export const videosSlice = createSlice({
  name: 'videos',
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    add: videosAdapter.addOne,
    remove: videosAdapter.removeOne,
    removeObject: (state, action: PayloadAction<Video>) => {
      const video = action.payload;
      videosAdapter.removeOne(state, video.id);
    },
    updateObject: (state, action: PayloadAction<[Video, Partial<Video>]>) => {
      const [video, changes] = action.payload;
      videosAdapter.updateOne(
        state,
        {
          id: video.id,
          changes: changes,
        }
      );
    },
    setAll: videosAdapter.setAll,
  },
});

const selectors = videosAdapter.getSelectors();
export const videosSelectors = videosAdapter.getSelectors<RootState>((state) => state.videos);
export const selectVideoById = videosSelectors.selectById;
export const selectVideoIdsByArtist = createSelector(
  selectors.selectAll,
  (state: EntityState<Video>, artist?: string) => artist,
  (videos, artist) => {
    return videos
      .filter(video => (video.artist === artist))
      .map(video => video.id)
  }
);
export const selectArtists = createSelector(
  videosSelectors.selectAll,
  videos => {
    const artistsSet = new Set(videos.map(video => video.artist));
    return Array.from(artistsSet).sort();
  }
);

export default videosSlice.reducer;
