import { createEntityAdapter, createSelector, createSlice, EntityId, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { Video, videosSlice, selectVideoById } from '../video/videosSlice';

import { RootState } from '../../app/store';
import { initialSectionProps } from './seed';


export type SectionProps = {
  ytVideoId: string,
  name: string,
  startSeconds: number,
};

export type Section = {id: EntityId} & SectionProps;

const compareSections = (a: Section, b: Section): number => {
  const ytVideoIdCompare = a.ytVideoId.localeCompare(b.ytVideoId);
  if (ytVideoIdCompare !== 0) {
    return ytVideoIdCompare;
  }
  // return Math.sign(a.startSeconds - b.startSeconds);
  return a.startSeconds - b.startSeconds;
}

export const createSection = (sectionProps: SectionProps): Section => {
  return {
    id: uuidv4(),
    ...sectionProps,
  };
};

const sectionsAdapter = createEntityAdapter<Section>({
  sortComparer: compareSections,
});

const initialState = sectionsAdapter.addMany(
  sectionsAdapter.getInitialState(),
  initialSectionProps.map(createSection)
);

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState: initialState,
  reducers: {
    add: sectionsAdapter.addOne,
    remove: sectionsAdapter.removeOne,
    update: sectionsAdapter.updateOne,
    setAll: sectionsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(videosSlice.actions.removeObject, (state, action: PayloadAction<Video>) => {
        const video = action.payload;
        const sectionIds = selectSectionIdsByYtVideoId(state, video.ytVideoId);
        sectionsAdapter.removeMany(state, sectionIds);
      })
      .addCase(videosSlice.actions.updateObject, (state, action) => {
        const [video, changes] = action.payload;
        const ytVideoId = video.ytVideoId;
        const updatedYtVideoId = changes.ytVideoId;
        if (updatedYtVideoId === undefined) {
          return;
        }
        const sectionIds = selectSectionIdsByYtVideoId(state, ytVideoId);
        const updates = sectionIds.map(sectionId => {
          return {
            id: sectionId,
            changes: {
              ytVideoId: updatedYtVideoId,
            },
          }
        });
        sectionsAdapter.updateMany(state, updates);
      });
  },
});

const selectors = sectionsAdapter.getSelectors();
export const sectionsSelectors = sectionsAdapter.getSelectors<RootState>((state) => state.sections);

export const selectSectionIdsByYtVideoId = createSelector(
  selectors.selectAll,
  (state: EntityState<Section>, ytVideoId?: string) => ytVideoId,
  (sections, ytVideoId) => {
    return sections
      .filter(section => (section.ytVideoId === ytVideoId))
      .map(section => section.id)
  }
);

export default sectionsSlice.reducer;
