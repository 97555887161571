import { VideoProps } from './videosSlice';

export const initialVideoProps: VideoProps[] = [
  {
    artist: 'Metallica',
    title: 'Master of Puppets',
    ytVideoId: 'h0jTR-EdcDI',
  },
  {
    artist: 'All That Remains',
    title: 'Not Alone',
    ytVideoId: 'mS0ZJsDC76w',
  },
  {
    artist: 'All That Remains',
    title: 'Six',
    ytVideoId: 'CA_aMp-MvLE',
  },
  {
    artist: 'The Black Dahlia Murder',
    title: 'What A Horrible Night To Have A Curse',
    ytVideoId: 'mEACzoMJuMA',
  },
];
