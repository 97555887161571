import { isAnyOf, createSlice, createSelector, PayloadAction, EntityId } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';

import { RootState, AppThunk } from '../../app/store';

import type { AppStartListening } from '../../app/listenerMiddleware';

import { Video, selectVideoById, selectVideoIdsByArtist } from '../video/videosSlice';
import { Section } from '../section/sectionsSlice';


export type SelectionState = {
  // selected artist to filter videos by
  artist?: string,

  videoIdByArtist: {[artist: string]: EntityId},

  // selected video
  videoId?: EntityId,

  // selected section
  sectionId?: EntityId,

  // is youtube player ready
  isPlayerLoaded: boolean,
};

const initialState: SelectionState = {
  videoIdByArtist: {},
  isPlayerLoaded: false,
};

export const selectionSlice = createSlice({
  name: 'selection',
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    reset: (state) => {
      // TODO: preserve video id and dispatch initial actions on
      // component load
      return {
        ...state,
        videoId: undefined,
        sectionId: undefined,
        isPlayerLoaded: false,
      };
    },
    setArtist: (state, action: PayloadAction<string>) => {
      // TODO: preserve selected video id, maybe default to selecting first?
      const artist = action.payload;
      state.artist = artist;
    },
    setVideo: (state, action: PayloadAction<Video>) => {
      const video = action.payload;
      state.videoId = video.id;
      state.sectionId = undefined;
      state.videoIdByArtist[video.artist] = video.id;
    },
    setSection: (state, action: PayloadAction<Section>) => {
      const section = action.payload;
      state.sectionId = section.id;
    },
    setIsPlayerLoaded: (state, action: PayloadAction<boolean>) => {
      state.isPlayerLoaded = action.payload;
    },
  },
});

// dispatch set video action after selecting artist to trigger
// video load
export const addListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(
      selectionSlice.actions.setIsPlayerLoaded,
      selectionSlice.actions.setArtist,
    ),
    effect: (action, listenerApi) => {
      const dispatch = listenerApi.dispatch;
      const state = listenerApi.getState();
      switch (action.type) {
        // re-construct state by issuing action
        case selectionSlice.actions.setIsPlayerLoaded.type: {
          const artist = state.selection.artist;
          const isPlayerLoaded = action.payload;
          if (artist !== undefined && isPlayerLoaded) {
            dispatch(selectionSlice.actions.setArtist(artist));
          }
          return;
        }
        case selectionSlice.actions.setArtist.type: {
          const artist = action.payload;
          // select previously selected - otherwise default to first video
          const firstVideoId = selectVideoIdsByArtist(state.videos, artist)[0];
          const videoId = state.selection.videoIdByArtist[artist];
          const video = selectVideoById(state, videoId) || selectVideoById(state, firstVideoId);
          dispatch(selectionSlice.actions.setVideo(video!));
          return;
        }
      };
    },
  });
};

export const selectSelectionState = (state: RootState) => state.selection;

export default selectionSlice.reducer;
