import { createRef, useRef, SyntheticEvent, KeyboardEvent, useState, CSSProperties } from 'react';
import { EntityId, PayloadAction, Update } from '@reduxjs/toolkit';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Icon } from './Hoverable';

type ComponentProps = {
  modalTitle: string,
  modalBody?: string,
  removeAction: PayloadAction<EntityId>,
};

export const RemoveButton = (
  {componentProps}:{componentProps: ComponentProps}
) => {
  const {modalTitle, modalBody, removeAction} = componentProps;

  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);
  const handleHide = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleConfirm = () => {
    dispatch(removeAction);
    handleHide();
  };

  return (
    <>
      <Icon
        className='bi-trash'
        hoverClassName='bi-trash-fill'
        isSelected={show}
        onClick={handleShow}
      />
      <Modal
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        {modalBody ? (<Modal.Body>{modalBody}</Modal.Body>) : null}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>Cancel</Button>
          <Button variant="primary" onClick={handleConfirm}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

