import classNames from 'classnames';
import { createElement, useEffect } from 'react';
import { BrowserRouter, Navigate, NavLink, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';

import { keyMatch, ShortcutKey, useAppDispatch, useKeyPress } from './app/hooks';

import { MetronomeConfigButtonGroup } from './features/config/MetronomeConfig';
import { PlayerConfigButtonGroup } from './features/config/PlayerConfig';
import { MetronomeControl } from './features/metronome/Metronome';
import { PlayerWrapper } from './features/player/Player';
import { PresetList } from './features/preset/Preset';
import { SectionListWrapper } from './features/section/Section';
import { TickControl } from './features/tick/Tick';
import { ArtistList, VideoListWrapper } from './features/video/Video';

import { metronomeSlice } from './features/metronome/metronomeSlice';
import { selectionSlice } from './features/selection/selectionSlice';

import './App.css';

type PageDef = {
  name: string,
  path: string,
  component: () => JSX.Element,
};

const getPageDefs = (): PageDef[] => {
  return [
    {
      name: 'metronome',
      path: '/metronome',
      component: MetronomeContainer,
    },
    {
      name: 'player',
      path: '/player',
      component: VideoPlayerContainer
    },
  ];
};


const App = () => {
  const pageDefs = getPageDefs();
  const indexRoute = <Route
    index
    element={<Navigate to={pageDefs[0].name} replace />}
  />
  const routes = pageDefs.map(({name, path, component}) =>
    <Route key={name} path={path} element={createElement(component)} />
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<PageNav />}>
          {indexRoute}
          {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const PageNav = () => {
  const pageDefs = getPageDefs();
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname: currentPath} = location;

  const shortcutKeys: ShortcutKey[] = pageDefs.map(({name, path}, i) => {
    return {
      key: (i + 1).toString(),
      ctrl: true,
    }
  });

  const navigateToPage = (event: KeyboardEvent) => {
    const pageDef = pageDefs.find((pageDef, i) => {
      const shortcutKey = shortcutKeys[i];
      return keyMatch(shortcutKey, event);
    })!;
    navigate(pageDef.path);
  };
  useKeyPress(shortcutKeys, navigateToPage);

  const cyclePage = (event: KeyboardEvent) => {
    const currentIndex = pageDefs.findIndex(({path}) => {
      return (path === currentPath);
    })!;
    const nextPageDef = pageDefs[(currentIndex + 1) % pageDefs.length];
    navigate(nextPageDef.path);
  };
  useKeyPress([{key: '`'}], cyclePage);

  const navItems = pageDefs.map(({name, path, component}, i) => {
    return (
      <NavLink key={name} className='nav-link' to={path}>{`${name} ^${i + 1}`}</NavLink>
    );
  });
  return (
    <div className='d-flex flex-column'>
      <Navbar>
        <Nav variant='tabs'>
          {navItems}
        </Nav>
      </Navbar>
      <Outlet />
    </div>
  );
};

const VideoPlayerContainer = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const reset = () => {
      dispatch(selectionSlice.actions.reset())
    };
    window.addEventListener('beforeunload', reset);

    // reset state on component unload as well as tab close
    return () => {
      reset();
      window.removeEventListener('beforeunload', reset);
    };
  }, []);

  return (
    <Container className='m-3'>
      <Row className='my-3'>
        <Col md={5}><PlayerWrapper /></Col>
        <Col>
        </Col>
        <Col md={1}>
          <PlayerConfigButtonGroup />
        </Col>
      </Row>
      <Row className='my-3'>
        <Col md={3}><ArtistList /></Col>
        <Col md={3}><VideoListWrapper /></Col>
        <Col md={4}><SectionListWrapper /></Col>
      </Row>
    </Container>
  );
};

const MetronomeContainer = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const reset = () => {
      dispatch(metronomeSlice.actions.reset())
    };
    window.addEventListener('beforeunload', reset);

    // reset state on component unload as well as tab close
    return () => {
      reset();
      window.removeEventListener('beforeunload', reset);
    };
  }, []);

  return (
    <Container className='m-3'>
      <Row className='my-3'>
        <Col md={5} className={classNames('d-flex', 'flex-column', 'align-items-center')}>
          <MetronomeControl />
          <hr className='w-100' />
          <TickControl />
        </Col>
        <Col md={4}><PresetList /></Col>
        <Col>
        </Col>
        <Col md={1}><MetronomeConfigButtonGroup /></Col>
      </Row>
    </Container>
  );
};

export default App;
