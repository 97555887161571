import { ConfigButtonGroup } from '../common/ConfigButtonGroup';

import { videosSlice, createVideo } from '../video/videosSlice';
import { sectionsSlice, createSection } from '../section/sectionsSlice';

import { RootState } from '../../app/store';


export const PlayerConfigButtonGroup = () => {

  const getActionsFromConfig = (config: any) => {
    const videos = config.videos.map(createVideo);
    const sections = config.sections.map(createSection);

    return [
      videosSlice.actions.setAll(videos),
      sectionsSlice.actions.setAll(sections),
    ];
  };

  const getConfigFromState = (state: RootState) => {
    const videosProps = Object.values(state.videos.entities).map(
      obj => {
        const {id, ...props} = obj!;
        return props;
      }
    );
    const sectionsProps = Object.values(state.sections.entities).map(
      obj => {
        const {id, ...props} = obj!;
        return props;
      }
    );
    const config = {
      videos: videosProps,
      sections: sectionsProps,
    };
    return config;
  };

  return (
    <ConfigButtonGroup
      importProps={{
        getActionsFromConfig: getActionsFromConfig,
      }}
      exportProps={{
        defaultFilename: 'playerConfig.json',
        getConfigFromState: getConfigFromState,
      }}
    />
  );
};
