import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AppStartListening } from '../../app/listenerMiddleware';
import { RootState } from '../../app/store';

import { Preset, presetsSlice } from '../preset/presetsSlice';
import { OscillatorType } from "./index";
import { Metronome, MetronomeProps } from './index';

// singleton metronome instance
export const metronome = new Metronome();

type MetronomeState = {
  metronomeProps: MetronomeProps,
  isStopped: boolean,
};

export const metronomePersistBlacklist = [
  'isStopped',
];

const initialState: MetronomeState = {
  metronomeProps: metronome.props,
  isStopped: true,
};

export const metronomeSlice = createSlice({
  name: 'metronome',
  initialState: initialState,
  reducers: {
    reset: (state) => {
      return {...state, isStopped: true};
    },
    setBpm: (state, action: PayloadAction<number>) => {
      const bpm = action.payload;
      if (!Metronome.isValidBpm(bpm)) {
        return;
      }
      state.metronomeProps.bpm = bpm;
    },
    setGain: (state, action: PayloadAction<number>) => {
      const gain = action.payload;
      if (!Metronome.isValidGain(gain)) {
        return;
      }
      state.metronomeProps.gain = gain;
    },
    setOscillatorType: (state, action: PayloadAction<OscillatorType>) => {
      state.metronomeProps.oscillatorType = action.payload;
    },
    setFrequency: (state, action: PayloadAction<number>) => {
      state.metronomeProps.frequency = action.payload;
    },
    setIsStopped: (state, action: PayloadAction<boolean>) => {
      state.isStopped = action.payload;
    },
    adjustBpm: (state, action: PayloadAction<number>) => {
      const diff = action.payload;
      const bpm = state.metronomeProps.bpm + diff;
      if (!Metronome.isValidBpm(bpm)) {
        return;
      }
      state.metronomeProps.bpm = bpm;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(presetsSlice.actions.selectPreset, (state, action: PayloadAction<Preset>) => {
        const preset = action.payload;
        const bpm = preset.bpm;
        if (!Metronome.isValidBpm(bpm)) {
          return;
        }
        state.metronomeProps.bpm = bpm;
        state.isStopped = false;
      });
  },
});

export const addListener = (startListening: AppStartListening) => {
  startListening({
    predicate: (action, currentState, previousState) => {
      return currentState.metronome != previousState.metronome;
    },
    effect: (action, listenerApi) => {
      const currentState = listenerApi.getState().metronome;
      const previousState = listenerApi.getOriginalState().metronome;
      if (currentState.metronomeProps !== previousState.metronomeProps) {
        metronome.setProps(currentState.metronomeProps);
      }
      if (currentState.isStopped !== previousState.isStopped) {
        if (currentState.isStopped) {
          metronome.stop();
        } else {
          metronome.start();
        }
      }
    },
  });
};

export const selectMetronomeState = (state: RootState) => state.metronome;
export const selectMetronomeProps = (state: RootState) => state.metronome.metronomeProps;

export default metronomeSlice.reducer;
