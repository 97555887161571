import { createEntityAdapter, createSlice, EntityId, EntityState, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { RootState, store } from '../../app/store';
import { metronomeSlice, selectMetronomeState } from '../metronome/metronomeSlice';
import { initialPresetProps } from './seed';

export type PresetProps = {
  name: string,
  bpm: number,
};

export type Preset = {id: EntityId} & PresetProps;

export const createPreset = (presetProps: PresetProps): Preset => {
  return {
    id: uuidv4(),
    ...presetProps,
  };
};

const presetsAdapter = createEntityAdapter<Preset>();

type PresetsState = {
  selectedId?: EntityId,
} & EntityState<Preset>;

const initialState: PresetsState = presetsAdapter.addMany(
  presetsAdapter.getInitialState(),
  initialPresetProps.map(createPreset)
);

export const presetsSlice = createSlice({
  name: 'presets',
  initialState: initialState,
  reducers: {
    add: presetsAdapter.addOne,
    remove: presetsAdapter.removeOne,
    update: presetsAdapter.updateOne,
    setAll: presetsAdapter.setAll,
    selectPreset: (state, action: PayloadAction<Preset>) => {
      state.selectedId = action.payload.id;
    },
    setIds: (state, action: PayloadAction<PresetsState['ids']>) => {
      state.ids = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(metronomeSlice.actions.setBpm, metronomeSlice.actions.adjustBpm),
        (state, action: PayloadAction<number>) => {
          state.selectedId = undefined;
        }
      )
  },
});

export const updateBpmFromMetronome = (presetId: EntityId) =>
  (dispatch: typeof store.dispatch, getState: typeof store.getState) => {
    const bpm = selectMetronomeState(getState()).metronomeProps.bpm;
    const action = presetsSlice.actions.update({
      id: presetId,
      changes: {bpm: bpm},
    });
    dispatch(action);
  }

export const selectPresetsState = (state: RootState) => state.presets;
export const presetsSelectors = presetsAdapter.getSelectors<RootState>((state) => state.presets);
export default presetsSlice.reducer;
