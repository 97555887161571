const NUM_SEMITONES = 12;
const INDEX_BY_NATURAL_NOTE = new Map([
  ['A', 0],
  ['B', 2],
  ['C', 3],
  ['D', 5],
  ['E', 7],
  ['F', 8],
  ['G', 10],
]);
export const NATURAL_NOTES = Array.from(INDEX_BY_NATURAL_NOTE.keys());

export type Accidental = 'sharp' | 'flat' | 'natural';
export type Pitch = {
  naturalNote: string,
  accidental: Accidental,
  octave: number,
};

export const REFERENCE_FREQUENCY = 440;
export const REFERENCE_PITCH: Pitch = {
  naturalNote: 'A',
  accidental: 'natural',
  octave: 4,
};

const getPitchIndex = (pitch: Pitch) => {
  const {naturalNote, accidental, octave} = pitch;
  const offset =
    (accidental === 'sharp') ? 1 :
    (accidental === 'flat') ? -1 :
    0;
  return octave * NUM_SEMITONES + INDEX_BY_NATURAL_NOTE.get(naturalNote)! + offset;
};

export const getFrequencyForPitch = (pitch: Pitch) => {
  const semitoneDiff = getPitchIndex(pitch) - getPitchIndex(REFERENCE_PITCH);
  const frequency = (
    Math.pow(2, semitoneDiff / NUM_SEMITONES)
    * REFERENCE_FREQUENCY
  );
  return frequency;
};

export const getGainForVolume = (volume: number) => {
  // https://www.reddit.com/r/programming/comments/9n2y0/comment/c0dgsjj/?utm_source=share&utm_medium=web2x&context=3
  // find db value that satifies human perception of pct volume (10 db -> 2x)
  // 100 volume => 0 db => 1 gain
  const db = Math.log2(volume / 100) * 10
  const gain = Math.exp(db / 20 * Math.log(10));
  return gain;
};
