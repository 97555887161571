import { createRef, useRef, SyntheticEvent, KeyboardEvent, useState, CSSProperties } from 'react';
import { EntityId, PayloadAction, Update } from '@reduxjs/toolkit';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import { useAppSelector, useAppDispatch, ShortcutKey, keysMatch } from '../../app/hooks';
import { Icon } from './Hoverable';

type Field = {
  name: string,
  inputType: string,
  placeholder: string,
  formProps?: CSSProperties,
  shouldFocus?: boolean,
};

type ComponentProps<T> = {
  fields: Field[],
  fieldsDirection?: 'horizontal' | 'vertical',
  getActionFromProps: (updateProps: any) => PayloadAction<any>,
  disableKeys?: ShortcutKey[],
};

export const UpdateButton = <T,>(
  {componentProps}: {componentProps: ComponentProps<T>}
) => {
  const {
    fields,
    fieldsDirection,
    getActionFromProps,
    disableKeys = [],
  } = componentProps;

  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);
  const buttonRef = useRef(null);

  const fieldsRef = useRef(
    fields.map(field => createRef<HTMLInputElement>())
  );
  const formControls = fields.map((field, i) =>
    (
      <Form.Control
        key={field.name}
        style={field.formProps}
        type={field.inputType}
        placeholder={field.placeholder}
        ref={fieldsRef.current[i]}
      />
    )
  );
  const shouldFocusRefs = fieldsRef.current.filter((fieldRef, i) =>
    fields[i].shouldFocus
  );

  const onEntered = () => {
    shouldFocusRefs.forEach(ref => ref.current!.focus())
  };

  const onSubmit = (event: SyntheticEvent) => {
    // prevent page reload
    event.preventDefault();
    const updateProps = Object.fromEntries(
      fields.map((field, i) =>
        [field.name, fieldsRef.current[i].current!.value]
      )
    );
    const action = getActionFromProps(updateProps);

    dispatch(action);
    setShow(false);
  };

  const directionClassName =
    (fieldsDirection === 'vertical')
    ? 'flex-column'
    : 'flex-row';

  // prevent keys in form from affecting outside
  const onKeyDown = (event: KeyboardEvent) => {
    if (keysMatch(disableKeys, event)) {
      event.stopPropagation();
    }
  };

  return (
    <div onKeyDown={onKeyDown}>
      <Icon
        className='bi-pencil'
        hoverClassName='bi-pencil-fill'
        isSelected={show}
        ref={buttonRef}
        onClick={() => setShow(!show)}
      />
      <Overlay
        placement='right-start'
        target={buttonRef.current}
        show={show}
        onHide={() => setShow(false)}
        onEntered={onEntered}
        rootCloseEvent='mousedown'
        rootClose
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => {
          return (
            <Popover {...props}>
              <Form onSubmit={onSubmit}>
                <div className={`d-flex gap-2 ${directionClassName}`}>
                  {formControls}
                </div>
                <Button type='submit' hidden />
              </Form>
            </Popover>
          );
        }}
      </Overlay>
    </div>
  );
};
