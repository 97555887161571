import { useEffect } from 'react';

import Placeholder from 'react-bootstrap/Placeholder';
import Spinner from 'react-bootstrap/Spinner';

import { useAppDispatch, useAppSelector, useKeyPress } from '../../app/hooks';
import { selectionSlice, selectSelectionState } from '../selection/selectionSlice';

import { RateControl, VolumeControl } from './Control';
import { Player, PlayerRef } from './playerSlice';

const PLACEHOLDER_ID = 'player';

export const SHORTCUT_KEYS = [
  {key: ' '},
  {key: 'm'},
  {key: 'ArrowDown'},
  {key: 'ArrowLeft'},
  {key: 'ArrowRight'},
  {key: 'ArrowUp'},
];

export const PlayerWrapper = () => {
  return (
    <div className='d-flex flex-row gap-4'>
      <PlayerPlaceholder />
      <PlayerControls />
    </div>
  );
};

const PlayerPlaceholder = () => {
  const dispatch = useAppDispatch();

  const width = 200;
  const height = 200;

  // load youtube player on first render
  useEffect(() => {
    const onReady = (player: ReturnType<typeof PlayerRef.getYtPlayer>, event: CustomEvent) => {
      // un-placeholder player element on load
      player.getIframe().then(node => node.classList.remove('placeholder'));
      dispatch(selectionSlice.actions.setIsPlayerLoaded(true));
    }
    PlayerRef.initialize(PLACEHOLDER_ID, width, height, onReady);
    return () => {
      Player.reset();
      PlayerRef.uninitialize();
      dispatch(selectionSlice.actions.setIsPlayerLoaded(false));
    }
  }, []);

  const playerPlaceholder = (
    <Placeholder
      id={PLACEHOLDER_ID}
      style={{width: width, height: height}}
      animation='glow'
      bg='dark'
      className='d-flex justify-content-center align-items-center'
    >
      <Spinner
        animation='border'
        variant='info'
        className='w-50 h-50'
      />
    </Placeholder>
  );
  return playerPlaceholder;
};

const PlayerControls = () => {

  const isPlayerLoaded = useAppSelector(
    state => selectSelectionState(state).isPlayerLoaded
  );

  const handleKeyPress = (event: KeyboardEvent) => {
    switch (event.key) {
      case ' ':
        return Player.togglePlay();
      case 'm':
        return Player.toggleMute();
      case 'ArrowDown':
        return Player.adjustVolume(-5);
      case 'ArrowUp':
        return Player.adjustVolume(+5);
      case 'ArrowLeft':
        return Player.adjustTime(-5);
      case 'ArrowRight':
        return Player.adjustTime(+5);
    }
  };
  useKeyPress(SHORTCUT_KEYS, handleKeyPress);

  if (!isPlayerLoaded) {
    return null;
  }

  return (
    <div className='d-flex flex-column'>
      <VolumeControl />
      <br />
      <RateControl />
    </div>
  );
};
