import { createRef, useRef, SyntheticEvent, KeyboardEvent, useState, CSSProperties } from 'react';
import { EntityId, PayloadAction, Update } from '@reduxjs/toolkit';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { useAppSelector, useAppDispatch, ShortcutKey, keysMatch } from '../../app/hooks';

type FieldCommonProps = {
  name: string,
  labelStyle?: CSSProperties,
  shouldFocus?: boolean,
  invalidValueMessage?: string,
};

type NumberField = {
  inputType: 'number',
  isValidValue?: (value: number) => boolean,
} & FieldCommonProps;

type TextField = {
  inputType: 'text',
  isValidValue?: (value: string) => boolean,
} & FieldCommonProps;

export type Field = (NumberField | TextField);

type ComponentProps<T> = {
  fields: Field[],
  fieldsDirection?: 'horizontal' | 'vertical',
  getActionFromProps: (addProps: any) => PayloadAction<T>,
  disableKeys?: ShortcutKey[],
};

export const AddForm = <T,>(
  {componentProps}: {componentProps: ComponentProps<T>}
) => {
  const {
    fields,
    fieldsDirection,
    getActionFromProps,
    disableKeys = [],
  } = componentProps;

  const dispatch = useAppDispatch();

  const [isInvalids, setIsInvalids] = useState(fields.map(() => false));
  const fieldsRef = useRef(
    fields.map(() => createRef<HTMLInputElement>())
  );

  const formControls = fields.map((field, i) =>
    (
      <FloatingLabel key={field.name} label={field.name} style={field.labelStyle}>
        <Form.Control
          key={field.name}
          type={field.inputType}
          ref={fieldsRef.current[i]}
          autoFocus={field.shouldFocus}
          isInvalid={isInvalids[i]}
        />
        <Form.Control.Feedback type='invalid'>
          {field.invalidValueMessage}
        </Form.Control.Feedback>
      </FloatingLabel>
    )
  );

  const onSubmit = (event: SyntheticEvent) => {
    // prevent page reload
    event.preventDefault();

    // check fields for validity
    const nextIsInvalids = fields.map((field, i) => {
      const stringValue = fieldsRef.current[i].current!.value;
      if (field.isValidValue === undefined) {
        return false;
      }
      switch(field.inputType) {
        case 'number':
          return !field.isValidValue(parseFloat(stringValue));
        case 'text':
          return !field.isValidValue(stringValue);
      }
    });
    setIsInvalids(nextIsInvalids);

    const isInvalid = nextIsInvalids.some(isInvalid => isInvalid);
    if (isInvalid) {
      event.stopPropagation();
      return;
    }

    const addProps = Object.fromEntries(
      fields.map((field, i) =>
        [field.name, fieldsRef.current[i].current!.value]
      )
    );
    const action = getActionFromProps(addProps);
    dispatch(action);

    (event.target as HTMLFormElement).reset();
    (document.activeElement as HTMLElement).blur();
  };
  const directionClassName =
    (fieldsDirection === 'vertical')
    ? 'flex-column'
    : 'flex-row';

  // prevent keys in form from affect outside
  const onKeyDown = (event: KeyboardEvent) => {
    if (keysMatch(disableKeys, event)) {
      event.stopPropagation();
    }
  };

  return (
    <Form noValidate onSubmit={onSubmit} onKeyDown={onKeyDown}>
      <div className={`d-flex gap-2 ${directionClassName}`}>
        {formControls}
      </div>
      <Button type='submit' hidden />
    </Form>
  );
};
