import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './app/store';
import App from './App';

import './index.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/darkly/bootstrap.min.css';
// import 'bootswatch/dist/sandstone/bootstrap.min.css';
// import 'bootswatch/dist/spacelab/bootstrap.min.css';
// import 'bootswatch/dist/sketchy/bootstrap.min.css';

import 'bootstrap-icons/font/bootstrap-icons.css';


const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);
