import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AppStartListening } from '../../app/listenerMiddleware';
import { RootState } from '../../app/store';

import { metronomeSlice } from '../metronome/metronomeSlice';
import {
  Pitch,
  REFERENCE_PITCH,
  getFrequencyForPitch,
  getGainForVolume
} from '.';

type TickState = {
  pitch: Pitch,
  volume: number,
};

const initialState: TickState = {
  pitch: REFERENCE_PITCH,
  volume: 50,
};

export const tickSlice = createSlice({
  name: 'tick',
  initialState: initialState,
  reducers: {
    setNaturalNote: (state, action: PayloadAction<Pitch['naturalNote']>) => {
      state.pitch.naturalNote = action.payload;
    },
    setAccidental: (state, action: PayloadAction<Pitch['accidental']>) => {
      state.pitch.accidental = action.payload;
    },
    setOctave: (state, action: PayloadAction<Pitch['octave']>) => {
      state.pitch.octave = action.payload;
    },
    setVolume: (state, action: PayloadAction<TickState['volume']>) => {
      state.volume = action.payload;
    },
  },
});

export const addListener = (startListening: AppStartListening) => {
  startListening({
    predicate: (action, currentState, previousState) => {
      return currentState !== previousState;
    },
    effect: (action, listenerApi) => {
      const dispatch = listenerApi.dispatch;
      const currentState = listenerApi.getState().pitch;
      const previousState = listenerApi.getOriginalState().pitch;
      if (currentState.pitch !== previousState.pitch) {
        const frequency = getFrequencyForPitch(currentState.pitch);
        dispatch(metronomeSlice.actions.setFrequency(frequency));
      }
      if (currentState.volume !== previousState.volume) {
        const gain = getGainForVolume(currentState.volume);
        dispatch(metronomeSlice.actions.setGain(gain));
      }
    },
  });
};

export const selectTickState = (state: RootState) => state.pitch;
export const selectPitch = (state: RootState) => state.pitch.pitch;

export default tickSlice.reducer;
