import { createListenerMiddleware, addListener } from '@reduxjs/toolkit'
import type { TypedStartListening, TypedAddListener } from '@reduxjs/toolkit'

import type { RootState, AppDispatch } from './store';
import * as metronomeSlice from '../features/metronome/metronomeSlice';
import * as playerSlice from '../features/player/playerSlice';
import * as pitchSlice from '../features/tick/tickSlice';
import * as selectionSlice from '../features/selection/selectionSlice';

export const listenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>

metronomeSlice.addListener(startAppListening);
playerSlice.addListener(startAppListening);
pitchSlice.addListener(startAppListening);
selectionSlice.addListener(startAppListening);
